import React from 'react';
import styled from 'styled-components';

const OverviewContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #1e2837;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const SeeDetails = styled.a`
  color: #3498db;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const StatItem = styled.div`
  text-align: left;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const StatValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #1e2837;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const StatLabel = styled.div`
  font-size: 12px;
  color: #777;
  margin-bottom: 4px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const PositiveText = styled.span`
  color: #4caf50;
  font-size: 12px;
  background-color: #e8f5e9;
  padding: 2px 4px;
  border-radius: 2px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

function PortfolioOverview({ holdings }) {
  const totalInvested = holdings.reduce((sum, holding) => sum + holding.investment, 0);
  const totalNAV = holdings.reduce((sum, holding) => sum + holding.nav, 0);
  const moic = totalNAV / totalInvested;
  const openPositions = holdings.length;
  const portfolioAge = 1; // 1 year

  // Simple IRR calculation (this is a simplified version and not accurate for multiple cash flows)
  const irr = Math.pow(totalNAV / totalInvested, 1 / portfolioAge) - 1;

  return (
    <OverviewContainer>
      <Header>
        <Title>PORTFOLIO OVERVIEW</Title>
        <SeeDetails>See Details</SeeDetails>
      </Header>
      <StatsGrid>
        <StatItem>
          <StatLabel>Total Invested</StatLabel>
          <StatValue>{`$${(totalInvested / 1000000).toFixed(2)}M`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>NAV</StatLabel>
          <StatValue>{`$${(totalNAV / 1000000).toFixed(2)}M`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>MOIC</StatLabel>
          <StatValue>{moic.toFixed(2)}x</StatValue>
          {moic > 1 && <PositiveText>+{((moic - 1) * 100).toFixed(2)}%</PositiveText>}
        </StatItem>
        <StatItem>
          <StatLabel>IRR</StatLabel>
          <StatValue>{(irr * 100).toFixed(2)}%</StatValue>
          {irr > 0 && <PositiveText>+{(irr * 100).toFixed(2)}%</PositiveText>}
        </StatItem>
        <StatItem>
          <StatLabel>Open Positions</StatLabel>
          <StatValue>{openPositions}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Portfolio Age</StatLabel>
          <StatValue>{portfolioAge} year</StatValue>
        </StatItem>
      </StatsGrid>
    </OverviewContainer>
  );
}

export default PortfolioOverview;
