import React from 'react';
import styled from 'styled-components';
import logo from '../armyncaplogo.png';

const SidebarContainer = styled.div`
  width: 184px;
  background-color: #1e2837;
  color: #ffffff;
  padding: 20px;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 132px;
  }
`;

const Logo = styled.img`
  width: 120px;
  margin-bottom: 40px;
  margin-top: 30px;
`;

const NavItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  opacity: ${props => props.active ? '1' : '0.7'};
  &:hover {
    opacity: 1;
  }
`;

function Sidebar() {
  return (
    <SidebarContainer>
      <Logo src={logo} alt="Armyn Capital Logo" />
      <NavItem active>Dashboard</NavItem>
    </SidebarContainer>
  );
}

export default Sidebar;
