import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
`;

const Title = styled.h1`
  margin: 0;
  padding-left: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    padding-left: 0;
    font-size: 20px;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <Title>Dashboard</Title>
    </HeaderContainer>
  );
}

export default Header;
