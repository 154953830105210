import React from 'react';
import styled from 'styled-components';
import logoSmall from '../logosmall.png';

const FooterContainer = styled.footer`
  background-color: #f8f9fa;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  font-size: 12px;
  color: #666;

  @media (max-width: 768px) {
    padding: 10px;
    text-align: center;
  }
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 40px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Disclaimer = styled.p`
  margin: 0;
`;

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <Logo src={logoSmall} alt="Armyn Capital Logo" />
      <Disclaimer>
        Caution: The information presented in this dashboard may not be entirely accurate. Historical performance does not guarantee future outcomes. Investment involves risks. Please consult with a financial advisor before making investment decisions. This dashboard is for informational purposes only and should not be considered as investment advice.
      </Disclaimer>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
