import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';

const TableContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-x: auto;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1e2837;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #e0e0e0;
  color: #777;
  font-weight: 700;
  font-size: 12px;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  color: #1e2837;
`;

const Tr = styled.tr`
  &:nth-child(odd) {
    background-color: #f8f9fa;
  }
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const LastRow = styled(Tr)`
  font-weight: bold;
`;

function HoldingsTable({ holdings, setHoldings }) {
  const [fundInvestments, setFundInvestments] = useState([]);

  useEffect(() => {
    // Fetch fund investments data
    fetch('/data/investor_123.csv')
      .then(response => response.text())
      .then(csvData => {
        const results = Papa.parse(csvData, { header: true, dynamicTyping: true });
        setFundInvestments(results.data);
      })
      .catch(error => console.error('Error loading fund investments:', error));
  }, []);

  const calculateTotals = (investments) => {
    const totalInvestment = investments.reduce((sum, inv) => sum + inv.investment, 0);
    const totalNAV = investments.reduce((sum, inv) => sum + inv.nav, 0);
    const totalMOIC = totalNAV / totalInvestment;
    return { totalInvestment, totalNAV, totalMOIC };
  };

  const renderTable = (data, title, headers) => {
    const { totalInvestment, totalNAV, totalMOIC } = calculateTotals(data);

    return (
      <TableContainer>
        <Title>{title}</Title>
        <Table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <Th key={index}>{header.toUpperCase()}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <Tr key={index}>
                {headers.map((header, idx) => (
                  <Td key={idx}>
                    {header === 'investment' || header === 'nav' 
                      ? `$${item[header].toLocaleString(undefined, {maximumFractionDigits: 0})}`
                      : header === 'moic'
                        ? <GreenText>{(item[header] || 0).toFixed(2)}x</GreenText>
                        : item[header]}
                  </Td>
                ))}
              </Tr>
            ))}
            <LastRow>
              <Td colSpan={headers.length - 3}>Total</Td>
              <Td>${totalInvestment.toLocaleString(undefined, {maximumFractionDigits: 0})}</Td>
              <Td>${totalNAV.toLocaleString(undefined, {maximumFractionDigits: 0})}</Td>
              <Td><GreenText>{totalMOIC.toFixed(2)}x</GreenText></Td>
            </LastRow>
          </tbody>
        </Table>
      </TableContainer>
    );
  };

  const spvHeaders = ['vehicleName', 'company', 'sector', 'date', 'investment', 'nav', 'moic'];
  const fundHeaders = ['fundName', 'focus', 'vintage', 'investment', 'nav', 'moic', 'irr'];

  return (
    <>
      {renderTable(holdings, 'SPV INVESTMENTS', spvHeaders)}
      {renderTable(fundInvestments, 'FUND INVESTMENTS', fundHeaders)}
    </>
  );
}

export default HoldingsTable;
