import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Container = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1e2837;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

const InvestmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const InvestmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvestmentName = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #1e2837;
  margin-bottom: 3px;
`;

const InvestmentValue = styled.span`
  font-size: 12px;
  color: #1e2837;
`;

const ProgressContainer = styled.div`
  width: 50px;
  height: 50px;
`;

function CapitalInvested({ holdings }) {
  console.log('CapitalInvested render, holdings:', holdings);

  if (!holdings || holdings.length === 0) {
    console.log('No holdings data available for CapitalInvested');
    return <Container>No data available for industry breakdown</Container>;
  }

  const totalInvested = holdings.reduce((sum, holding) => sum + holding.investment, 0);
  
  const sectors = holdings.reduce((acc, holding) => {
    if (acc[holding.sector]) {
      acc[holding.sector] += holding.investment;
    } else {
      acc[holding.sector] = holding.investment;
    }
    return acc;
  }, {});

  const sectorPercentages = Object.entries(sectors).map(([sector, amount]) => ({
    sector,
    amount,
    percentage: (amount / totalInvested) * 100
  })).sort((a, b) => b.percentage - a.percentage);

  console.log('Sector percentages:', sectorPercentages);

  return (
    <Container>
      <Title>Industry Breakdown</Title>
      <ScrollContainer>
        {sectorPercentages.map(({ sector, amount, percentage }, index) => (
          <InvestmentItem key={sector}>
            <InvestmentInfo>
              <InvestmentName>{sector}</InvestmentName>
              <InvestmentValue>${amount.toLocaleString()}</InvestmentValue>
            </InvestmentInfo>
            <ProgressContainer>
              <CircularProgressbar
                value={percentage}
                text={`${percentage.toFixed(1)}%`}
                styles={buildStyles({
                  textColor: "#66ccff",
                  pathColor: "#66ccff",
                  trailColor: "#d6d6d6",
                  textSize: '22px'
                })}
              />
            </ProgressContainer>
          </InvestmentItem>
        ))}
      </ScrollContainer>
    </Container>
  );
}

export default CapitalInvested;
